import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { history } from '../_helpers';
import cokeandgo from "../assets/images/landing/cokeandgo.png";
import logo from "../assets/images/landing/white-logo.png";
import rewarding_logo from "../assets/images/landing/rewarding_logo_us.png";
import square_logo from "../assets/images/landing/icon/square-logo.png";
import phoneimage from "../assets/images/landing/icon/activated_img_us.png";
import firstIcon from "../assets/images/landing/icon/01_us.png";
import secondIcon from "../assets/images/landing/icon/02_us.png";
import thirdIcon from "../assets/images/landing/icon/03_us.png";
import fourthIcon from "../assets/images/landing/icon/04_us.png";
import mobimage from "../assets/images/landing/icon/mob-group_us.png";
import playstore from "../assets/images/landing/playstore_us.png";
import apple from "../assets/images/landing/apple_us.png";
import "../assets/scss/landing.scss";
import { Trans } from 'react-i18next';
import moment from 'moment';
import { config, env } from '../_config';
import queryString from 'query-string';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { isAndroid, isIOS } from "react-device-detector";
import { encryptData } from '../_helpers/encryptData';
import { getAppId } from "../_helpers/getAppId";
import error from "../_assets/images/icons/error.png";
import { Modal, Button} from 'antd';
import { ClipLoader } from "react-spinners";
const ApplicationTypeId = getAppId();

export default class ContinueOption extends Component {
    constructor(props){
        super(props)
        document.title=`Coke & Go ${config.countryName}`;
        CapturePage({hitType:"pageview",page:"/ContinueOption"})  
        this.state = {
            showPopup: false,
            isLoading: true
        }
        
        const urlpart = this.props.location.search.slice(1)
        let {qr:qrcode="", coolerType="",assetType=""} = queryString.parse(this.props.location.search);
        if (qrcode!='') {
            localStorage.setItem("qrcode", encryptData(qrcode));
        }
        this.qrcode = qrcode;
        // if(ApplicationTypeId==12 && (coolerType.toLowerCase()=="televend"||assetType.toLowerCase()=="televend")){
        //     history.push(`/continueoption-tvm?${urlpart}`)
        // }
        if (ApplicationTypeId == 20) {
            fetch("https://api.ipify.org?format=json")
                .then((json) => { return json.json() })
                .then(async (result) => {
                    const ipAddress = result.ip;
                    const pageName = window.location.href;
                    const applicationTypeId = ApplicationTypeId;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams({ ipAddress, applicationTypeId, pageName }),
                    };
        
                    await fetch(`${config.envUrl}VendingAppApi/appSiteHitLog`, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(data);
                            if (isAndroid) {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.grabtaxi.passenger&hl=en_SG&pli=1";
                            } else if (isIOS) {
                                window.location.href = "https://apps.apple.com/sg/app/grab-taxi-ride-food-delivery/id647268330";
                            } else {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.grabtaxi.passenger&hl=en_SG&pli=1";
                            }
                        })
                        .catch((error) => {
                            console.error("Error logging site hit:", error);
                        });
                });
        }
        else if(ApplicationTypeId==8){
            // window.location.href =  urlpart ? `https://coca-cola-us-vending-machine-qa.azurewebsites.net/ContinueOption?${urlpart}` : `https://coca-cola-us-vending-machine-qa.azurewebsites.net/ContinueOption`
            fetch("https://geolocation-db.com/json/")
            .then((json) => { return json.json() })
            .then(async (result) => {
                const ipAddress = result.IPv4;
                const pageName = window.location.href
                const applicationTypeId = 8;
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams({ ipAddress, applicationTypeId, pageName }),
                };
                await fetch(
                    `${config.envUrl}VendingAppApi/appSiteHitLog`,
                    requestOptions
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {                        
                        this.setState({ isLoading: false });
                    })
                    .catch(()=>{                        
                        this.setState({ isLoading: false });

                    })
            })
            .catch(()=>{
                this.setState({ isLoading: false });

            })
            this.handleRegistrationFlow()
        }
        else{
            history.push('/introSlider1');
        }
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleRegistrationFlow = this.handleRegistrationFlow.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleRedirect(){
        let checkOnboarding = localStorage.getItem('onBoarding');
        if(checkOnboarding){            
            history.push('/Link');
        }
        else{            
            history.push('/introSlider1');
        }
    }

    handleRegistrationFlow = async () => {
        let {qr , coolerType="",assetType=""} = queryString.parse(this.props.location.search);
        const urlpart = this.props.location.search.slice(1)
        

        if (qr) {
            this.setState({ isLoading: true });
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    macAddress: qr,
                    applicationTypeId: 8
                })
            };
    
            try {
                const response = await fetch(`${config.envUrl}VendingAppApi/getRegistrationFlow`, requestOptions);
                const data = await response.json();
    
                if (data.success) {
                    const flowType = data.flowType;
                    if (flowType === 'New'){
                        this.setState({ isLoading: false });
                        history.push('/hurryform')
                    }
                    // else{
                    //     if(env=="QA"){
                    //         window.location.href = "https://coca-cola-us-vending-machine-qa.azurewebsites.net/ContinueOption"
                    //     }
                    //     else if(env=="Pre-Prod"){
                    //         window.location.href = `https://coca-cola-us-vending-machine-qa.azurewebsites.net/ContinueOption?${urlpart}`
                    //     }
                    //     else{
                    //         window.location.href = "https://cokenow-vending-machine.azurewebsites.net/ContinueOption"
                    //     }
                    // }
                } 
                else {
                    this.setState({
                        isLoading: false,
                        showPopup: true
                      })
                }
            } catch (error) {
                this.setState({
                    isLoading: false,
                    showPopup: true
                  })
            }
        } 
    };
    handleClick(){
        if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS"
        }
        else if (isIOS) {
            window.location.href = "https://apps.apple.com/us/app/cokenow/id1594145032"
        }
        else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS"
        }
    }
    render() {
        if (ApplicationTypeId === 20) {
            return null; 
        }
        let year = moment().format("YYYY")
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        const {showPopup, isLoading} = this.state;
        return (
            <>
                <div className="main-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-Header">
                                    <div className="logo">
                                        <img src={cokeandgo} width="179" height="52" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 position-none">
                                    <h1 className="landing_title mb-0 d-none d-md-block">Unlock refreshment</h1>
                                    <h1 className="landing_title mb-0 d-md-none text-mid">Unlock refreshment at one of Coke’s smart coolers.</h1>
                                    <button className='btn btn-landing btn-top d-md-none' onClick={this.handleClick}>
                                        Download App
                                    </button>
                                    <div className='mobgroup d-md-none'>
                                        <img src={mobimage} />
                                    </div>
                                    <div className='landing_sub_title d-none d-md-block'>
                                        <img src={rewarding_logo} />
                                    </div>
                                    <div className="phoneImage d-none d-md-block">
                                        <img src={phoneimage} />
                                    </div>
                                    <div className='d-flex d-md-none' style={{ marginLeft: '10%' }}>
                                        <div className='cokelogo mt-3'>
                                            <img src={square_logo} height="80" />
                                        </div>
                                        <div className='cokedesc mt-4'>
                                            <h4 className='mb-0'>Coca-Cola&GO</h4>
                                            <h6>Rewarding refreshment</h6>
                                        </div>
                                    </div>
                                    <div className='USfeatures'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <h4>Features:</h4>
                                                <ul>
                                                    <li>Conveniently purchase Coca-Cola products with touchless pay.</li>
                                                    <li>Enjoy a clean, convenient, touch-free experience</li>
                                                    <li>Cool rewards. Earn points every time you purchase a drink.</li>
                                                    <li>Great variety. Choose from a wide range of your Coke favorites.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="mt-5 d-none d-md-block">
                                        {/* <button className="btn btn-round btn-dark">Download App</button>
                                            <p className="text-white my-2">Or</p>
                                            <p><Link to="/introSlider1" className="text-white pb-2">Continue on the Web</Link></p>  */}
                                        <div className="download" style={{ marginLeft: "10%" }}>
                                            <h6>Download Coca-Cola&GO now</h6>
                                            <a href='https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS'><img src={playstore} /></a>
                                            <a href='https://apps.apple.com/us/app/cokenow/id1594145032'><img src={apple}/></a>
                                            {/* <p className='continueweb'>Or <Link to="/introSlider1" className=" pb-2">Continue on the Web</Link></p> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mob-landing-footer mt-5 d-md-none">
                        <div className="">
                            <button className='btn btn-bottom d-md-none mob-button' onClick={this.handleClick}>Download App</button>
                            {/* <p className='continueweb'>Or <br></br> <Link to="/introSlider1" className=" pb-2">Continue on the Web</Link></p> */}
                        </div>
                    </div>
                    <div className='bg-grey'>
                        <div className='container'>
                            <div className="basic_details_flex">
                                <div><a className="" style={{fontFamily:'Segoe UI'}}><img src={firstIcon} alt="" /><span>01</span>Scan the QR code to create your account</a></div>
                                <div><a className="" style={{fontFamily:'Segoe UI'}}><img src={secondIcon} alt="" /><span>02</span>Within the app, scan QR code to purchase</a></div>
                                <div><a className="" style={{fontFamily:'Segoe UI'}}><img src={thirdIcon} alt="" /><span>03</span>Pick your favorite beverage</a></div>
                                <div><a className="" style={{fontFamily:'Segoe UI'}}><img src={fourthIcon} alt="" /><span>04</span>Grab, go, and enjoy!</a></div>
                                {/* <div><a className=""><img src={fifthIcon}  alt=""/><span>05</span>Close the cooler door and enjoy!</a></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-center">
                                    <img src={logo} />
                                    <p className="my-4 text-uppercase text-white" style={{fontFamily:'Segoe UI', fontSize:'16px'}}>© {year} the Coca-cola company. All rights reserved.</p>
                                </div>
                                <div className="col-md-12 text-center">
                                    <ul className="footerList">
                                        <li>
                                            <a style={{fontFamily:'Segoe UI', fontSize:'16px', textDecoration: 'underline'}} href="https://us.coca-cola.com/support/contact-us">Contact US</a>
                                        </li>
                                        <li>
                                            <a style={{fontFamily:'Segoe UI', fontSize:'16px', textDecoration: 'underline'}} href="https://us.coca-cola.com/privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a style={{fontFamily:'Segoe UI', fontSize:'16px', textDecoration: 'underline'}} href="https://us.coca-cola.com/privacy-policy">do not sell my personal information</a>
                                        </li>
                                        <li>
                                            {/* <Link to={{pathname:"/Terms-of-use",state:"/ContinueOption"}}>terms & conditions</Link> */}
                                            <a style={{fontFamily:'Segoe UI', fontSize:'16px', textDecoration: 'underline'}} href='https://us.coca-cola.com/terms-of-use'>terms & conditions</a>
                                        </li>
                                        <li>
                                            <a style={{fontFamily:'Segoe UI', fontSize:'16px', textDecoration: 'underline'}} href="https://us.coca-cola.com/privacy-policy#california-collection">California notice at collection</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={showPopup}
                    footer={false}
                    headers={false}
                    title={false}
                    closable={false}
                    centered
                    style={{ maxWidth: '200px' }}
                    bodyStyle={{ padding: '15px'}}
                >
                    <div style={{ textAlign: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <img src={error} style={{height: '35px',marginTop: '5px', marginRight: '4px' }}></img>
                            <label style={{fontWeight: '500', marginBottom: '13px', textAlign: 'left'}}>Some thing went wrong, please try again later.</label>
                        </div>
                    </div>
                </Modal>
                {isLoading &&
                (<div className="theme-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                    <div style={style}>
                        <ClipLoader
                        size={50}
                        color={"#FF0000"}
                        loading={isLoading}
                        />
                    </div>
                </div>)
                }
            </>
        )
    }
}
